import React,{Component} from 'react';
import {
    Link
  } from 'react-router-dom';
import axios from 'axios';
// import renderHTML from 'react-render-html';
import { Modal }  from 'antd-mobile';

const alert = Modal.alert;

class PackageStorePage extends Component {
    constructor(props) {
      super(props);
  
      window.BPdata.footerMenu = "MORE";
      this.state = {
        top : {
            description : ''
        },
        bottom : {
          description : ''
        },
        
        isLoading: false,
      };

    }

 

    componentDidMount() {
        // this.fetchData();
        // this.setState({ loading: true });
        this.hisUrl('/more/package/store');
    }

    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        } 
         if(menu.length > 0) {
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  localStorage.setItem("route", JSON.stringify(menu) );
              }  
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.BPdata.preurl =  menu[menu.length-2];
          }
      }
    }

    componentDidUpdate() {
      
    }

    fetchData() {
        let dataurl = window.BPdata.apiUrl+'/user/package/store';

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            this.setState({
              top: resp.data.top,
              bottom: resp.data.bottom
            });
            this.setState({ isLoading: false });
            // console.log(this.state.data);
        })
        .catch(function(error) {
        //   console.log(error.tostring());
        });   
 
    }

    

    displayBlock() {
        let ui = []
        // console.log("liked--------"+this.state.liked)
        if(this.state.top  != null){
            ui.push(
                <div className="main-content" key="1">
                    <div className="row content-box text-left">
                        <br/>
                        <div className="list-header">
                            <a href="/more/gems/store" rel="noopener noreferrer"><img src="/img/badge.jpg" className="list-img-circle" alt="Package Store" /><span>Package Store</span></a>
                        </div>
                        <br />
                        <h2>Top Player Predictions</h2>
                        <br />
                        <div className="grid-100">
                                <div className="grid-40  bg-gray package-block">
                                    <img src="/img/tipster.png" alt="တစ်ပတ်စာ"/><br/>
                                    <b className="color-green">တစ်ပတ်စာ</b><br/><br/>
                                    <button className="btn btn-success" onClick={() =>
                                        alert('Submit', 'Are you sure want to order?', [
                                        { text: 'မ၀ယ်ဘူး', onPress: () => console.log('cancel') },
                                        { text: '၀ယ်မယ်', onPress: () => this.sendPlayerPredictApi('weekly')  },
                                        ])
                                    }>Order</button>
                                    {/* <button className="btn-secondary btn">Buy</button> */}
                                </div>
                                <div className="grid-40 bg-gray package-block">
                                <img src="/img/tipster.png" alt="Monthly"/><br/>
                                    <b className="color-green">တစ်လစာ</b><br/><br/>
                                    <button className="btn btn-success" onClick={() =>
                                        alert('Submit', 'Are you sure want to order?', [
                                        { text: 'မ၀ယ်ဘူး', onPress: () => console.log('cancel') },
                                        { text: '၀ယ်မယ်', onPress: () => this.sendPlayerPredictApi('monthly')  },
                                        ])
                                    }>Order</button>
                                </div>
                        </div>
                        {/* <p className="league-price-table ">
                            { renderHTML(this.state.top.description) }
                        </p>
                        <p>
                            { renderHTML(this.state.bottom.description) }
                        </p> */}
                    </div> 
                    
                   
                </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }
        
        return ui
    }


    
    render() {
   

        return (
            <div className="page">
                <div className="page-content">
                    
                        { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock() }
                           
                        <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>
                        
                </div>
            </div>
            );
        }
      }                                                                                         
      
export default PackageStorePage;
 