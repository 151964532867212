import React from 'react';
import axios from 'axios';
import { Grid,Modal } from 'antd-mobile';
// import Swiper from 'swiper/dist/js/swiper.min.js';
import Swal from 'sweetalert2';
import swal from 'sweetalert';

// CommonJS

import AppHeader from './AppHeader';
import {
    Link
  } from 'react-router-dom';

require('./../../BPdata.js')

var moment = require('moment');
const alert = Modal.alert;
//var moment = require('moment');

class PackagePage extends React.Component {

    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "promotions";
        this.state = {
            step: "intial",
            message : "",
            
            news      : [{}],
            data : [
                {id:    1, title : "Web App", type  : 0, description : "", link:"", created_at : "2019-05-21 08:14:30" },
            ],
            form : { phone: '',otpcode: '', password: '' },
            isCounting: false,
            seconds: 60,
            phone   : "",
            package_id : 0,
        };
        

        this.handlePackage = this.handlePackage.bind(this);
        this.resendOTP           = this.resendOTP.bind(this);
        this.checkValidationApi = this.checkValidationApi.bind(this);
        // this.changePasswordApi  = this.changePasswordApi.bind(this);
        // this.handleClick = this.handleClick.bind(this);
    }

    // this.validatePhone      = this.validatePhone.bind(this);
    // this.resendOTP           = this.resendOTP.bind(this);
    // this.checkValidationApi = this.checkValidationApi.bind(this);
    // this.changePasswordApi  = this.changePasswordApi.bind(this);


        startCountdown = () => {
            this.setState({ isCounting: true });
            const intervalId = setInterval(() => {
                this.setState((prevState) => {
                    if (prevState.seconds === 0) {
                        clearInterval(intervalId);
                        this.setState({ isCounting: false });
                        return { seconds: 60 }
                    }
                    return { seconds: prevState.seconds - 1 }
                });
            }, 1000);
        }

        handleOtpRequest = () => {
            // Send the phone number to the server to request a new OTP
            // ...
            this.startCountdown();
        }

        purchasePackage(price) {
            this.setState({  message : ""  });
            var self = this;
            let dataurl = window.BPdata.apiUrl+'/package/'+price;
                        
            axios({
                method:'POST',
                url:dataurl,
                headers: { 'content-type': 'application/json;charset=UTF-8',
                    "X-Trident-Token" : window.BPdata.token,
                    "BP-Agent"        : localStorage.getItem(window.BPdata.time),
                },
            })
            .then(function(response){

                if(response.data.status == "403") {
                    // console.log('ok')
                    
                    Swal.fire(
                        'Event charging Failed!',
                        'လုပ်ဆောင်မူ့မအောင်မြင်ပါ။',
                        'error'
                    )

                    self.setState({  step : "intial" });

                }

                if(response.data.status == "200") {
                    // console.log('ok')
                    
                    Swal.fire(
                        'Success!',
                        'အောင်မြင်စွာဝယ်ယူပြီးပါပြီ!',
                        'success'
                    )

                    self.setState({  step : "intial" });
                }
            })
            .catch(function(error){
                // console.log(error)
            })
        }

        checkValidationApi() {
            let formdata = this.state.form ;
            var self = this;
    
            // let dataurl = window.BPdata.apiUrl+'/user/verifycode';
            let dataurl = window.BPdata.apiUrlCMS+'/player/verifycode-otpp';
            // let dataurl = window.BPdata.apiUrlCMS+'/player/sim-login';
            
            axios({
                method: 'post',
                url: dataurl,
                data: formdata,
                headers: { 'content-type': 'application/json;charset=UTF-8',
                    "X-Trident-Token" : window.BPdata.token,
                    "BP-Agent"         : window.BPdata.rand_compound,
                    "BP-Time"         : localStorage.getItem(window.BPdata.time)
                },
            })
            .then(function (response) {
                if(response.data.data.status == 403) {
                    self.setState({  message : response.data.data.message });
                } else if(response.data.data.status == 200) {
    
                  
    
                    if(response.data.data.type == "login") {
    
                        self.setState({  message : response.data.data.message  });

                        localStorage.setItem("bp-ath-time", response.data.data.token);
                        
                        self.purchasePackage( self.state.package_id )
    
                    }
                    // self.setState({  message : response.data.data.message , step : "password" });
                    // localStorage.setItem("username", response.data.data.username);
                    // localStorage.setItem("bp-ath-time", response.data.data.token);
                    // Redirect.push('/');
                    this.history.push("/more/myaccount");
                } else {
                    self.setState({  message : "Something was wrong" });
                }
                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        }

        resendOTP() {

            let data = {
                phone : this.state.form.phone
            }

            this.resendSMSApi(data);

        }

        newOTP() {

            let data = {
                phone : this.state.form.phone
            }

            this.newOTPSMSApi(data);

        }


        newOTPSMSApi(formdata) {
            var self = this;
            // let dataurl = window.BPdata.apiUrlCMS+'/player/password-reset';
            // let dataurl = window.BPdata.apiUrlCMS+'/player/sim-login';
            let dataurl = window.BPdata.apiUrlCMS+'/player/package-otp';
            // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
            axios({
                method: 'post',
                url: dataurl,
                data: formdata,
                headers: { 'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
              },
            })
            .then(function (response) {
                if(response.data.data.status == 403) {
                    self.setState({  message : response.data.data.message });
                } else if(response.data.data.status == 200) {
                    self.setState({  message : "SMS ပို့ပြီးပါပြီ", step : "verify" });
                  
                    self.handleOtpRequest();
                }  else {
                    self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
                }
                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        }

    
        resendSMSApi(formdata) {
            var self = this;
            // let dataurl = window.BPdata.apiUrlCMS+'/player/password-reset';
            // let dataurl = window.BPdata.apiUrlCMS+'/player/sim-login';
            let dataurl = window.BPdata.apiUrlCMS+'/player/package-otp';
            // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
            axios({
                method: 'post',
                url: dataurl,
                data: formdata,
                headers: { 'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
              },
            })
            .then(function (response) {
                if(response.data.data.status == 403) {
                    self.setState({  message : response.data.data.message });
                } else if(response.data.data.status == 200) {
                    self.setState({  message : "SMS ပို့ပြီးပါပြီ", step : "verify" });
                    self.handleOtpRequest();
                }  else {
                    self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
                }
                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        }


    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    viewHandler() {

        switch(this.state.step) {
            case "intial": return this.displayBlock2();
            case "verify": return this.verifyBlock();
            default:      return "";
          }
    }

    verifyBlock() {
        return <div>
                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>{ this.state.phone } သို့ ပေးပို့ပြီးပါပြီ။ <br /> {window.lang.form.verify_code} &nbsp;&nbsp;
                                {this.state.isCounting && <div>Time remaining: {this.state.seconds} seconds</div>}
                                {!this.state.isCounting && <a onClick={this.resendOTP} className="color-red pointer">(Resend OTP)</a>}</label>
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-center"  name="otpcode" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.otpcode} maxLength="6" /><br />
                            </div>
                            <input type="submit" className="btn btn-yellow   pointer grid-50" value="Submit" onClick={this.checkValidationApi} /><br />
                        </div>

                    </div>
        </div>
    }

    handlePackage(e,price){

        this.setState({
            package_id : 0
        })

        this.setState({
            package_id : price
        })

        alert('Message', 'Package ဝယ်ယူရန် သေချာပါသလား။', [
            { text: 'စဉ်းစားအုန်းမည်', onPress: () => console.log('cancel') },
            {
            text: 'ဝယ်ယူမည်',
            onPress: () => {

                    const self = this;
                    

                    
                    self.newOTP();

                    
                    
                }

            },
        ])
        
        
    }
 
    displayBlock2( ) {
        let data = this.state.news
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []

                ui.push(<div className="league-box" key="0">
                        
                        <div className="row">
                            {/* <table className="table  table-border league-table" >
                                <tbody> */}
                                {this.childui(0,data)}  
                                    {/* </tbody>
                            </table> */}
                        </div>
                </div>)
            return ui
        }
    }
    
   
    childui(id,data) {
        let ui = []
        // console.log(id)
            //console.log("-------------"+data)
        
            if(Array.isArray(data) === false) {
                // console.log(data)
                // console.log(this.state.contests)
            } else {

                let arlength = data.length;
                
                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) { 
                        let each = data[i]
                        
                        ui.push(
                            <div className="flex" key={"a"+i}>
                                <div className='flex-item-right'>
                                    <div className="cardPrice">
                                        <div className="container-fluid price">
                                        <span className=''>{each.coin} Coins = {each.amount} MMK</span>
                                        <br /><br />
                                        <img src={'/img/coin/'+each.image}  width="150px" className='img-thumbnail'  alt="" />
                                        </div>
                                        

                                        <button className="buy" onClick={(e)=>this.handlePackage(e,each.id)}>
                                            {window.lang.buy}
                                        {/* <a href="#" className="buy">Buy</a> */}
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        )

                        
                }

                if(ui.length === 0) {
                    //this.setState({status : 1});
                        ui.push(<div className="text-center" key="0"><h2>There is no event today</h2></div>)
                }
                    return ui
                }
        }

        // console.log(status+"-------")
        // if(status === 1) {
        //     console.log(status+"-------")
        //     this.setState({status : 0});
        // }
        
        return ui;
    }

    displayBlock( ) {
       

        return <div className="">
                    
                    <div className="flex">
                        <div className='flex-item-right'>
                            <div className="cardPrice">
                                <div className="container-fluid price">
                                <span className=''>100 MMK = 100 Coins</span>
                                </div>
                                <img src="/img/coin/100coins.png" width="230px" className='img-thumbnail'  alt="" />

                               <button className="buy" onClick={(e)=>this.handlePackage(e,"100")}>Buy
                               {/* <a href="#" className="buy">Buy</a> */}
                                </button>
                              
                                {/* <span className='btn btn-sm' onClick={this.handlePackage("100")}></span>                                 */}
                            </div>
                        </div>
                        <div className='flex-item-right'>
                            <div className="cardPrice">
                                <div className="container-fluid price">
                                <span className=''>500 MMK = 525 Coins</span>

                                </div>
                                <img src="/img/coin/525coins.png" width="230px" className='img-thumbnail'  alt="" />


                               <button className="buy" onClick={(e)=>this.handlePackage(e,"500")}>Buy</button>
                                
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className='flex-item-right'>
                            <div className="cardPrice">
                                <div className="container-fluid price">
                                <span className=''><b>1000 MMK = 1100 Coins</b></span>

                                </div>
                                <img src="/img/coin/1100coins.png" width="230px" className='img-thumbnail'  alt="" />



                               <button className="buy" onClick={(e)=>this.handlePackage(e,"1000")}>Buy</button>
                                
                            </div>
                        </div>
                        <div className='flex-item-right'>
                            <div className="cardPrice">
                                <div className="container-fluid price">
                                <span className=''>5000 MMK = 5700 Coins</span>

                                </div>
                                <img src="/img/coin/5700coins.png" width="230px" className='img-thumbnail'  alt="" />




                               <button className="buy" onClick={(e)=>this.handlePackage(e,"5000")}>Buy</button>
                                
                            </div>
                        </div>
                    </div>

                
                </div>

    }
 

    componentDidMount() {

        window.scrollTo(0, 0);

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "intial" });
            }

          }

        if(localStorage.getItem('noti')) {
            if(JSON.parse(localStorage.getItem("noti")) != null) {
              let noti = JSON.parse(localStorage.getItem("noti"));
              this.setState({data: noti})

            }
        }

        window.scrollTo(0, 0);

       

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);
              
                this.fetchUserData();
                this.setState({
                    step : "intial"
                });

              
  
            } else {
               this.props.history.push("/more/login");
               return ;
              }
          } else {
              this.props.history.push("/more/login");
              return ;
          }

           this.getListApi();
    }

    fetchUserData() {

        const url = window.BPdata.apiUrl+'/cms/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;


        axios({
          method: 'post',
          url: url,
          data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {


            if(resp.data.data) {
                this.setState({
                    phone : resp.data.data.phone,
                    form : {
                            phone: resp.data.data.phone,
                            otpcode: '', 
                            password: ''
                    }
                });

                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )
            } else {

                localStorage.setItem(window.BPdata.time, 0);
                localStorage.setItem('username', "");

                swal({
                  title: "Session Expire",
                  text: window.lang.errors.contact_cs,
                  icon: "warning",
                  dangerMode: true,
                }).then(willDelete => {
                    if (willDelete) {
                        localStorage.setItem(window.BPdata.time, 0);
                        localStorage.setItem('username', "");
                        this.props.history.push("/more/login")
                    }
                });


            }


          })
          .catch(function(error) {
            // console.log(error);
          });
    }


    getListApi(){

        let dataurl = window.BPdata.apiUrl+'/packageList';
        axios.get(dataurl, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
          })
          .then(resp => {
              this.setState({
                     news : resp.data.data
                  // bottom      : resp.data.bottom.data,
                  // status      : resp.data.top.data.status,
                  // advertise   : resp.data.advertise.data,
              }); 
              
            //   console.log(resp.data.data);
              // console.log( resp.data.top.data)
              // this.state.isLoading = false;
              
          })
          .catch(function(error) {
          //   console.log(error.tostring());
          });  

        //   console.log(this.state.news)
        
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }
    
    render() {
        
        return (
            <div className="page">

                <AppHeader titleType="2" title={window.lang.title.promotions} isLoading="false" />
                <div className="page-content">
                    <div className="main-content text-center">

                        <div className="row text-left">
                            <div className="page-content">
                                <br />
                                <div className="row text-center">
                                    <span className="color-red">{this.state.message}</span>
                                </div>
                                
                                <br/>
                                { this.viewHandler() }
                            </div>
                        </div>
                    </div>
                </div>
                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default PackagePage;
