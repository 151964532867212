/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView } from 'antd-mobile';

import LoadingSpinner from "./../../LoadingSpinner";

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 100;
let LIMIT_ROWS = 100;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_TITLE_LENGTH = 50;
let MAX_LENGTH = 90;
let FIRST_MAX_LENGTH = 400;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class AppHeader extends React.Component {

  constructor(props) {
    super(props);


    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {

      dataSource: dataSource.cloneWithRows(this.data),
      news      : [],
      isLoading: false,
      advertise: [],
      titleType : 1,
      title     : '',
      back_link : '',

    };

    this.pageIndex = 1;
    if(this.props.titleType) {

      this.state.titleType = this.props.titleType;
      this.state.title = this.props.title;
      this.state.back_link = this.props.backLink;
      //console.log(TOTAL_INDEX)
      // this.state.isLoading = true;

    }

    this.langSwitch         = this.langSwitch.bind(this);
    this.pageRefresh        = this.pageRefresh.bind(this);

  }

  componentDidMount() {

    if(localStorage.getItem('home_advertise')) {

      if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
        let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
        this.setState({advertise: home_advertise})
      }
    }

    // if(localStorage.getItem('latest_news')) {
    //   if(JSON.parse(localStorage.getItem("latest_news")) != null) {
    //     let latest_news = JSON.parse(localStorage.getItem("latest_news"));
    //     this.setState({news: latest_news})

    //   }
    // }

    this.fetchData();


    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // // simulate initial Ajax
    // setTimeout(() => {
    //   this.fetchData();
    // }, 600);

  }

  componentWillUnmount() {
    // clearInterval(this.state)
  }

  fetchData() {

        //const url = window.BPdata.apiUrl+'/news?limit='+NUM_ROWS+'&start=10&featured=0';
        const url = window.BPdata.apiUrlCMS+"/news";

        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          // this.data = resp.data.news.data;
          // console.log("-----------------");

            this.setState({
              // news      : this.data,
              // dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false,
              // advertise: resp.data.advertise
            });

            localStorage.setItem("all_games", JSON.stringify(resp.data.all_games) )

            localStorage.setItem("home_advertise", JSON.stringify(resp.data.advertise) )

            //NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)

        })
        .catch(function(error) {
          //console.log(error);
        });
  }


  langSwitch() {

        if(localStorage.getItem('lang') === 'en') {
            localStorage.setItem('lang', 'mm');
            window.lang = window.mmlang;
        } else {
            localStorage.setItem('lang', 'en');
            window.lang = window.enlang;
        }

        window.location.reload();
        // console.log(window.lang)
        // this.props.history.push("/");
  }

  pageRefresh() {
    
      this.setState({
        isLoading : true
      });
       window.location.reload();
  }

  render() {
    // const separator = (sectionID, rowID) => (
    //   <div
    //     key={`${sectionID}-${rowID}`}
    //     style={{
    //       backgroundColor: '#F5F5F9',
    //       height: 8,
    //       borderTop: '1px solid #ECECED',
    //       borderBottom: '1px solid #ECECED',
    //     }}
    //   />
    // );
    // let index = this.data.length - 1;
    // let ui;
    // let adcount = 0;

    return (
      <div>
         <header className="App-header">
        <div className="App-header-inner">
          <div className="row text-center">
            <div className="align-left left grid-25 logo ">
                <div className="grid-100">
                  <img src="/img/logo.png" className="header-logo" alt="AThaPyar" height="45"/>
                </div> 
            </div>
            <div className="align-center left grid-50 logo1">


              <Link to="/" className="color-white">
                {/* <img src="/img/header-logo.png" className="header-logo" alt="AThaPyar" height="45"/> */}
                
              </Link>
            </div>
            <div className="align-right right grid-25 parent">

              <Link to="/more/announcements" className="pointer ">
                <img src="/img/_noti.png"  alt="profile" height="28px"/>
              </Link>


              <img src="/img/_switch.png"  alt="profile" onClick={this.pageRefresh} className="pointer pl-10" height="28px"/>


              {/* <div className="download-apk-here">
                  {
                    ((navigator.userAgent.toLowerCase().indexOf("BPdata") > -1 ) ? "" : <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.BPdata.ss" >Download APK Here</a> )
                  }
              </div> */}

            </div>
          </div>
        </div>
      </header>
      {this.state.isitLoading ? <LoadingSpinner /> : ""}
      </div>
    );
  }
}

export default AppHeader;
