let rand_one = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let rand_two = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let salt = "r27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zc";
let token = window.btoa(encodeURIComponent( rand_one + salt  + rand_two ));
let rand_compound = window.btoa(encodeURIComponent( rand_one + "!" + rand_two ));
//let token = encodeURIComponent(escape(window.atob( rand_one + salt  + rand_two )));
// atob(rand_one + salt  + rand_two);
// api.athapyar22.com
let httpsbase = "//api.athapyar22.com";
let httpbase = "http://api.athapyar22.com";

let httpdualbase = "//api.athapyar22.com";

// let httpsbase = "https://api.athapyar22.com";
// let httpbase = "https://api.athapyar22.com";

window.BPdata =
    {
        time : "bp-ath-time",
        provider_id : 1,
        data :
            {
                path: '/',
                component: 'Home',
            },
        footerMenu : "Home",
        baseUrl : httpsbase+'/',
        apiGameUrl : httpsbase+'/api',
        apiUrl  : httpsbase+'/api/v1',
        apiUrlCMS  : httpsbase+'/api/v1/cms',
        apiUrlCMSNoS  : httpbase+'/api/v1/cms',
        apiUrlCMSDual  : httpdualbase+'/api/v1/cms',
        salt : salt,
        rand_one : rand_one,
        rand_two : rand_two,
        rand_compound : rand_compound,
        token : token,
        dashboardUrl : httpsbase+'',
        apiAssetUrl : httpsbase+'/uploads',
        popup   : true,
        route : "[\"/\"]",
        preurl: "/",
        liveurl: "",
        mainmenu :[
            { id: 1, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီးမီးယားလိဂ်',  url : 'premier-league' },
            { id: 2, name: 'Spain Laliga Premier', shortname: 'LALIGA', mmshortname: 'လာလီဂါ', url : 'laliga' }
          ],
        lang : "mm"
    }
;


window.enlang =
{
    more : {
        faq_title : "We're here to help you with anything and everyting on AThaPyar",
        faq_ans : " At AThaPyar everything we expect at a day's start is you.better and happier than yesterday. We have got you covered. Share your concern or check our frequently asked questions listed below.",
        my_account          : 'My Account',
        live_channel1       : 'Live Channel 1',
        live_channel2       : 'Live Channel 2',
        live_channel3       : 'Live Channel 3',
        channelsschedule    : 'Channels Schedule',
        gem_store           : 'Gem Store',
        term_and_conditions : 'Terms & Conditions',
        announcement        : 'Announcement',
        feedback            : 'Feedback',
        changepassword      : 'Change Password',
        faq                 : 'FAQ',
        video               : 'Skill Videos',
        help_center         : 'Help Center',
        wallet_store        : 'Wallet',
        contact_us          : 'Contact Us',
        download_app        : 'Download App',
        sub                 : 'Unsubscribe',
        share_app           : 'Share App',
        version_check       : 'Update Check',
        version_code        : 'Version Code :',
        statement           : 'Statement',
    },
    BetListDetail:{
        start:"Lottery Start Time",
        end:"Lottery End Time",
        odds:"Odds",
        number:"Lottery Number",
    },
    title : {
        date:"Update at",
        off_day : "Date",
        topthree:"Top Three List",
        close_day : "Close Day",
        latest_euro_news      : 'LATEST EURO NEWS',
        latest_news           : 'LATEST NEWS',
        latest_highlights     : 'LATEST HIGHLIGHTS',
        league_contest  : 'League Contest',
        bill_contest    : 'Bill Contest',
        tipsters        : 'Tipsters',
        tip             : 'Tips',
        league_history  : 'League History',
        bill_history    : 'Bill History',
        transaction     : 'Transaction',
        contest         : 'Contest',
        leaderboard     : 'Leaderboard',
        history         : 'History',
        euro_news       : 'Euro News',
        euro_standing   : 'Euro Standing',
        euro_team       : 'Euro Team',
        payment         : 'Payment',
        order           : 'Order',
        forum           : 'Forum',
        inbox           : 'Inbox',
        withdrawal      : 'Withdrawal',
        deposit         : 'Deposit',
        withdrawal_history      : 'Withdraw',
        top_up_history         : 'Top Up',
        top_up          : 'Top Up',
        transfer        : 'Transfer',
        bet_list        : 'Bet List',
        result          : 'Result',
        winner          : 'Winner',
        close_day       : 'Close Day',
        dream_number    : 'Dream Number',
        main_wallet     : 'Main Wallet',
        // game_wallet     : 'Game Wallet',
        game_wallet     : 'Wallet',
        coin            : 'Coins',
        commission_wallet     : 'Commission Wallet',
        promotions            : 'Promotions',
        service               : 'Service',
        twod                  : '2D',
        threed                : '3D',
        my_account            : 'My Account',
        faq                   : 'FAQ',
        wallet_store          : 'Wallet Store',
    },
    form : {
        user            : 'User',
        id              : 'ID',
        withdrawal      : 'Withdrawal',
        deposit         : 'Deposit',
        top_up          : 'Top Up',
        transfer        : 'Transfer',
        gems            : 'Gems',
        point           : 'Point',
        hightest_point  : 'Highest Point',
        rank            : 'Rank',
        join_date       : 'Join Date',
        language        : 'Language',
        login           : 'User Login',
        register        : 'User Register',
        password_reset  : 'Password Reset',
        verify_code     : 'Verify Code',
        logout          : 'Logout',
        title           : 'Title',
        description     : 'Description',
        name            : 'Name',
        bank            : 'Bank',
        acc_phone_num   : 'Phone Number',
        amount          : 'Amount',
        phone           : 'Phone',
        email           : 'Email',
        date_of_birth   : 'Date of Birth',
        choose_state    : 'Choose State',
        password        : 'Password',
        old_password    : 'Old Password',
        new_password    : 'New Password',
        re_new_password : 'Retype New Password',
        forgot_password : 'Forgot password?',
        submit          : 'Submit',
        cancel          : 'Cancel',
        new_post        : 'New Post',
        comment         : 'Comment',
        add_bank_account: 'Add Bank',
        bank_list       : 'Bank List',
        from            : 'From',
        to              : 'To',
        from_arrow      : 'Previous',
        to_arrow        : 'Next',
        date            : 'Date',
        que             : {
            confirm     : 'Confirm Box',
            delete      : 'Are you sure to delete?'
        }
    },
    packages : {
        oneday          : 'One Day',
        oneweek         : 'One Week',
        onemonth        : 'One Month',
        buy             : 'Buy',
    },
   
    footer : [
            {icon: '/img/footer/_home.png',icon_off: '/img/footer/_color_home.png',text: 'Home', check: 'home', url : "/" },
            {icon: '/img/footer/_wallet.png',icon_off: '/img/footer/_color_wallet.png',text: 'Leaderboard', check: 'leaderboard', url : "/more/league" },
            {icon: '/img/footer/_promotions.png',icon_off: '/img/footer/_color_promotions.png',text: 'Package', check: 'promotions', url : "/more/package" },
            {icon: '/img/footer/_contact.png',icon_off: '/img/footer/_color_contact.png',text: 'Services', check: 'service', url : "/contact-us" },
            {icon: '/img/footer/_profile.png',icon_off: '/img/footer/_color_profile.png',text: 'My Profile', check: 'myaccount', url : "/more/myaccount" },
          ],
    homeicongame : [{ id: 0, icon: '/img/game_cat_1.png',icon_off: '/img/game_cat_1.png',text: 'All', second_text: 'All Game', check: 'All', url : "/more/announcements", game_type : "all"},
        { id: 4,icon: '/img/game_cat_5.png',icon_off: '/img/game_cat_5.png',text: 'Fishing', second_text: 'Fishing Game', check: 'Fishing', url : "/more" , game_type : "FishGame" },
        { id: 3,icon: '/img/game_cat_4.png',icon_off: '/img/game_cat_4.png',text: 'Slot', second_text: 'Slot Game', check: 'Slot', url : "/more" , game_type : "WSS" },
          { id: 1, icon: '/img/game_cat_2.png',icon_off: '/img/game_cat_2.png',text: 'Sports', second_text: 'Sport Game', check: 'Sports', url : "/more/wallet-store" , game_type : "SlotGame"},
          { id: 2,icon: '/img/game_cat_3.png',icon_off: '/img/game_cat_3.png',text: 'Keno', second_text: 'Keno Game', check: 'Keno', url : "/contact-us" , game_type : "Keno" },
          { id: 2,icon: '/img/game_cat_3.png',icon_off: '/img/game_cat_3.png',text: 'Other', second_text: 'Other Game', check: 'Other', url : "/contact-us"  , game_type : "Other" },
          
    ],
    homeiconlist : [
            {icon: '/img/homeicon/home-block-live-sports.jpg',icon_off: '/img/homeicon/home-block-live-sports.jpg',text: 'Football', check: 'FOOTBALL', url : "/ws-football-full" , extraurl : "/ws-football" ,  linktype : "local"},
            {icon: '/img/homeicon/pc_live.png',icon_off: '/img/homeicon/pc_live.png',text: 'Live Casino', check: 'NEWS', url : "/bg-live-casino", extraurl : "/bg-live-casino-full" , linktype : "local"},
            {icon: '/img/homeicon/pc_fish1.png',icon_off: '/img/homeicon/pc_fish1.png',text: 'Fishing Master', check: 'Profile', url : "/bg-fishing-master", extraurl : "/bg-fishing-master-full", linktype : "local"},
            // {icon: '/img/homeicon/pc_fish2.png',icon_off: '/img/homeicon/pc_fish2.png',text: 'Xiyou Fishing', check: 'ABOUT', url : "/bg-xiyoufishing", linktype : "local" },
            // {icon: '/img/homeicon/pc_fish3.png',icon_off: '/img/homeicon/pc_fish3.png',text: 'Daisen', check: 'ABOUT', url : "/bg-daisen", linktype : "local" },
    ],
    messages : {

        title : "The Following phone numbers are",
        title_one : "Organization's service phone numbers",
        company : "AThaPyar",
        company_one : "About AThaPyar",
        day : "24 hours a day No holidays are provided.",
        viber: "Viber",
        connect : "Connect with",
        connect_one : "Service Phone Number",
        time:"Deposit and Withdrawal / Service Time",
        add_one : "You can click here to see ",
        add_two : "what you need to know ",
        last_six_digit_transaction : "Please input last 6 digit of transaction ",
        choose_bank     : "Choose Bank",
        choose_top_bank : "Choose Bank for top up",
        choose_withdraw_bank : "Choose Bank for withdrawal",
        cash_amount     : "Cash Amount",
        que_submit      : "Are you sure want to submit?",
        invalid_phone   : "Invalid phone number",
        welcome_message : "Welcome to AThaPyar",
        sending         : "Sending",
        somethingwrong  : "Something was wrong",
        not_available_yet : "Not Available Yet",
        good        : "Good",
        good_morning: "Morning!",
        good_afternoon: "Afternoon!",
        good_evening: "Evening!",
        good_night: "Night!",
        lucky_number    : "Lucky Number",
        any_trouble_viber : "If any trobule, Please Contact to our viber",
        share_your_friend : "Share to your friend by using these",
        feedback_title          :  "We would like your feedback to improve our AThaPyar.",
        feedback_second_title   :  "Please leave your feedback below:",
        minamount         : "အနည်းဆုံးငွေ(၃၀၀၀)ကျပ်မှစ၍ ငွေထုတ်ရပါမည်",
        num_day : [
            { text: 'Sunday'},
            { text: 'Monday'},
            { text: 'Tueday'},
            { text: 'Wednesday'},
            { text: 'Thursday'},
            { text: 'Friday'},
            { text: 'Saturday'},
        ],
    },
    errors : {
        contact_cs      : "The session has expired!!!",
        check_bank_acc  : "Please check your Bank Account",
        w_request_processing : "Your withdrawal is being processed. Please wait.",
        check_amount    : "Please check your Amount",
    },
    moneyamountlist :  [
            { text: '1,000', num: '1000'},
            { text: '2,000',  num: '2000'},
            { text: '3,000',  num: '3000'},
            { text: '5,000', num: '5000'},
            { text: '10,000',  num: '10000'},
            { text: '50,000',  num: '50000'},
            { text: '100,000',  num: '100000'},
            { text: '200,000',  num: '200000'},
            { text: '500,000',  num: '500000'},
        ],
    betgroup : {
        select_number_group : "Select Number Group",
        big     : "Big",
        small   : "Small",
        odd     : "Odd",
        even    : "Even",
        same    : "Same",
        round   : "Round",
        head    : "Head",
        tail    : "Tail",
        consetellation : "Consetellation",
        power   : "Power",
        select_number : "Select Number",
        fast    : "Fast",
        clear   : "Clear",
        bet     : "Bet",
        bet_amount : "Bet",
        commission : "Commission",
        action     : "Action",
        edit       : "Edit",
        delete     : "Delete",
        commission_total : "Commission Total",
        total            : "Total",
        submit           : "Submit",
        color_meaning    : "Color Meaning",
        color_box_one   : "Below 50%",
        color_one : "Black",
        color_box_two   : "Within 50% to 90%",
        color_two : "Green",
        color_box_three : "Above 90% ",
        color_three : "Yellow",
        color_four : "Red",
        color_box_four : "Bet Amount Full",
        close_at         : "*Close at ",
        select_time      : "Select the time",
        same_number      : "Same Number",
        same_numbers     : "Same Numbers",
        hundred_number   : "Hundred Number",
        hundred_numbers   : "Hundred Numbers",
        lottery_holiday   : "Today is the lottery holiday",
        tom_lottery_holiday : "Tommorrow is the lottery holiday",
    },
    buy:"Buy",
};

window.mmlang =
{
    buy :"ဝယ်မည်",
    more : {
        faq_title : "ကျွန်ုပ်တို့သည် AThaPyar ရှိအရာအားလုံးကိုလူကြီးမင်းတို့အားကူညီပေးရန်ဤနေရာတွင်ရှိနေပါသည်။",
        faq_ans: "လူကြီးမင်းတို့၏အခက်အခဲများ ကို မျှဝေပေးပါ သို့မဟုတ်  ကျွန်ုပ်တို့ကိုမကြာခဏမေးလေ့ရှိသောမေးခွန်းများကိုအောက်တွင်ဖော်ပြထားပါသည်",
        my_account          : 'ကိုယ်ပိုင်အကောင့်',
        live_channel1       : 'တိုက်ရိုက်ချန်နယ် ၁',
        live_channel2       : 'တိုက်ရိုက်ချန်နယ် ၂',
        live_channel3       : 'တိုက်ရိုက်ချန်နယ် ၃',
        channelsschedule    : 'ချန်နယ်အချိန်ဇယား',
        gem_store           : 'Gem ဆိုင်',
        term_and_conditions_title : "“အဿပြာ 22”",
        term_and_conditions : 'စည်းကမ်းချက်များ',
        term_and_conditions_second: "အဿပြာ 22 ဝန်ဆောင်မှု (ဤနေရာတွင် ဝန်ဆောင်မှု ဟု ရည်ညွှန်းရန်) ကို စာရင်းပေးသုံးစွဲခြင်းဖြင့် ATOM ၏ စာရင်းပေးသုံးစွဲသူများ (“စာရင်းပေးသုံးစွဲသူ” သို့မဟုတ် “စာရင်းပေးသုံးစွဲသူများ”) သည် ဤစာချုပ်ထဲတွင် သတ်မှတ်ထားသော စည်းကမ်းချက်များကို နားလည်ပြီး သဘောတူပါသည်။ ဤဝန်ဆောင်မှုသည် အောက်ဖော်ပြပါ စည်းကမ်းချက်များနှင့်  ပြည်ထောင်စုမြန်မာနိုင်ငံတော်၏ သက်ဆိုင်ရာ ဥပဒေများ၊ စည်းမျဉ်းများနှင့် အညီဖြစ်ရမည်။",
        announcement        : 'အသိပေးကြေညာချက်',
        changepassword      : 'လျှို့ဝှက် နံပတ်ပြောင်းရန်',
        feedback            : 'အကြံပြုစာ',
        faq                 : 'အမြဲမေးလေ့ရှိသော မေးခွန်းများ',
        video               : 'ဗွီဒီယိုများ',
        help_center         : 'မေးလေ့မေးထရှိသောမေးခွန်းများ',
        wallet_store        : 'ပိုက်ဆံအိတ်',
        contact_us          : 'ဆက်သွယ်ရန်',
        download_app        : 'အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ။',
        sub                 : 'စာရင်းသွင်းခြင်းမှ ဖြုတ်ရန်။',
        // sub                 : 'Subscription',
        share_app           : 'အက်ပ်ကို မျှဝေပါ။',
        version_check       : 'Update Check',
        version_code        : 'Version Code :',
        statement           : 'မှတ်တမ်းထုတ်ပြန်ချက်',
    },
    BetListDetail:{
        start:"ထီထိုးချိန်",
        end:"ထီထွက်ချိန်",
        odds:"အဆ",
        number:"ပေါက်ဂဏန်း",
    },
    title : {
        date:"အပ်ဒိတ်ချိန်",
        off_day : "ရက်စွဲ",
        topthree:"Top Three List",

        close_day : "ပိတ်ရက်များ",
        latest_euro_news        : 'နောက်ဆုံးရ ယူရိုသတင်းများ',
        latest_news             : 'နောက်ဆုံးရသတင်းများ',
        latest_highlights       : 'နောက်ဆုံးရဟိုက်လိုက်များ',
        league_contest  : 'League ပြိုင်ပွဲ',
        bill_contest    : 'ဖုန်းဘေလ်ပြိုင်ပွဲ',
        transaction     : 'ငွေသွင်းငွေထုတ်',
        tipsters        : 'ခန့်မှန်းပေးသူများ',
        tip             : 'ခန့်မှန်းချက်',
        league_history  : 'လိဂ်ပြိုင်ပွဲသမိုင်း',
        bill_history    : 'ဖုန်းဘေလ်ပြိုင်ပွဲသမိုင်း',
        contest         : 'ပြိုင်ပွဲ',
        leaderboard     : 'အမှတ်ပေးဇယား',
        history         : 'ဆုရရှိသူများစာရင်း',
        euro_news       : 'ယူရို သတင်း',
        euro_standing   : 'ယူရို ရပ်တည်မှုဇယား',
        euro_team       : 'ယူရို အသင်းများ',
        payment         : 'ငွေပေးချေမှု',
        order           : 'မှာယူခြင်း',
        forum           : 'ဖိုရမ်',
        inbox           : 'စာတိုက်ပုံး',
        withdrawal      : 'ငွေထုတ်ရန်',
        deposit         : 'ငွေဖြည့်ရန်',
        withdrawal_history     : 'ငွေထုတ် မှတ်တမ်း',
        top_up_history         : 'ငွေဖြည့် မှတ်တမ်း',
        top_up          : 'ငွေဖြည့်ရန်',
        transfer        : 'ငွေလွှဲရန်',
        bet_list        : 'ရိုက်ချက်များ',
        result          : 'ပေါက်ကွက်များ',
        winner          : 'ထီပေါက်သူများ',
        close_day       : 'ထီပိတ်ရက်များ',
        dream_number     : 'အိမ်မက်ဂဏန်း',
        main_wallet     : 'ပင်မ ပိုက်ဆံအိတ်',
        game_wallet     : 'အဿပြာအိတ်',
        coin            :"အသပြာ",
        commission_wallet     : 'ကော်မရှင် ပိုက်ဆံအိတ်',
        promotions            : 'ပရိုမိုးရှင်း',
        service               : 'ဝန်ဆောင်မှုဖုန်း',
        twod                  : '2D',
        threed                : '3D',
        my_account            : 'ကိုယ်ပိုင်အကောင့်',
        faq                   : 'FAQ',
        wallet_store          : 'ပိုက်ဆံအိတ်',
    },
    form : {
        user            : 'အသုံးပြုသူ နာမည်',
        id              : 'မှတ်ပုံတင်အမှတ်',
        withdrawal      : 'ငွေထုတ်',
        deposit         : 'ငွေဖြည့်',
        top_up          : 'ငွေဖြည့်',
        transfer        : 'ငွေလွှဲပြောင်း',
        gems            : 'Gems',
        point           : 'ရမှတ်',
        hightest_point  : 'အမြင့်ဆုံးရမှတ်',
        rank            : 'အဆင့်',
        join_date       : 'အသင်းဝင်ရက်စွဲ',
        language        : 'ဘာသာစကား',
        login           : 'အကောင့်၀င်ရန်',
        register        : 'အကောင့်မှတ်ပုံတင်ရန်',
        logout          : 'ထွက်မည်',
        password_reset  : 'စကားဝှက်ကို ပြန်လည်သတ်မှတ်မည်',
        verify_code     : 'ကုဒ်အတည်ပြုပါ',
        title           : 'ခေါင်းစဉ်',
        description     : 'ဖော်ပြချက်',
        name            : 'နာမည်',
        bank            : 'ဘဏ်',
        acc_phone_num   : 'ဖုန်းနံပါတ်',
        date_of_birth   : 'မွေးရက်',
        amount          : 'ပမာဏ',
        phone           : 'ဖုန်းနံပါတ်',
        email           : 'အီးမေးလ်',
        choose_state    : 'ပြည်နယ် ရွေးချယ်ရန်',
        password        : 'စကားဝှက်',
        old_password    : 'စကားဝှက် အဟောင်းထည့်ပေးရန်',
        new_password    : 'စကားဝှက် အသစ်သတ်မှတ်ပေးရန်',
        re_new_password : 'စကားဝှက် အသစ်ကိုထပ်မံ ရိုက်ထည့်ပေးရန်',
        forgot_password : 'စကားဝှက် မေ့နေပါသလား။',
        submit          : 'လုပ်ဆောင်မည်',
        cancel          : 'မလုပ်ဆောင်ပါ',
        new_post        : 'အသစ်တင်မည်',
        comment         : 'မှတ်ချက်',
        add_bank_account: 'ဘဏ်တိုးရန်',
        bank_list       : 'ဘဏ်စာရင်း',
        from            : 'မှ',
        to              : 'သို့',
        from_arrow      : 'နောက်သို့',
        to_arrow        : 'ရှေ့သို့',
        date            : 'ရက်စွဲ',
        que             : {
            confirm     : 'အတည်ပြုပါ',
            delete      : 'သင်ဖျက်ဖို့သေချာလား။'
        }
    },
    packages : {
        oneday          : 'တစ်ရက်စာ',
        oneweek         : 'တစ်ပတ်စာ',
        onemonth        : 'တစ်လစာ',
        buy             : '၀ယ်မယ်',
    },
    footer : [
            {icon: '/img/footer/_home.png',icon_off: '/img/footer/_color_home.png',text: 'နန်းတော်', check: 'home', url : "/" },
            {icon: '/img/footer/_wallet.png',icon_off: '/img/footer/_color_wallet.png',text: 'အမှတ်ပေးဇယား', check: 'leaderboard', url : "/more/league" },
            {icon: '/img/footer/_promotions.png',icon_off: '/img/footer/_color_promotions.png',text: 'ပက်ကေ့ချ်', check: 'promotions', url : "/more/package" },
            {icon: '/img/footer/_contact.png',icon_off: '/img/footer/_color_contact.png',text: 'ဝန်ဆောင်မှုဖုန်း', check: 'service', url : "/contact-us" },
            {icon: '/img/footer/_profile.png',icon_off: '/img/footer/_color_profile.png',text: 'ကိုယ်ပိုင်အကောင့်', check: 'myaccount', url : "/more/myaccount" },
          ],
    homeicongame : [{ id: 0, icon: '/img/game_cat_1.png',icon_off: '/img/game_cat_1.png',text: 'အားလုံး',         second_text: 'Game အားလုံး', check: 'All', url : "/more/announcements", game_type : "all"},
          { id: 1, icon: '/img/game_cat_2.png',icon_off: '/img/game_cat_2.png',text: 'အမဲလိုက်ဂိမ်း', second_text: 'အမဲလိုက်ဂိမ်းများ', check: 'Fishing', url : "/more/wallet-store", game_type : "FishGame" },
          { id: 2,icon: '/img/game_cat_3.png',icon_off: '/img/game_cat_3.png',text: 'အားကစား', second_text: 'အားကစားများ', check: 'Sports', url : "/more", game_type : "WSS" },
          { id: 3,icon: '/img/game_cat_4.png',icon_off: '/img/game_cat_4.png',text: 'အကြွေစေ့လှည့်စက်', second_text: 'အကြွေစေ့လှည့်စက်များ', check: 'Slot', url : "/more"  , game_type : "SlotGame"},
          { id: 4,icon: '/img/game_cat_5.png',icon_off: '/img/game_cat_5.png',text: 'ကီနို', second_text: 'ကီနိုများ', check: 'Casino', url : "/contact-us", game_type : "Keno" },
          { id: 5,icon: '/img/game_cat_6.png',icon_off: '/img/game_cat_6.png',text: 'အခြားဂိမ်း', second_text: 'အခြားဂိမ်းများ', check: 'Casino', url : "/contact-us" , game_type : "Other" },
          
    ],
    homeiconlist : [
            {icon: '/img/homeicon/home-block-live-sports.jpg',icon_off: '/img/homeicon/home-block-live-sports.jpg',text: 'Football', check: 'FOOTBALL', url : "/ws-football" , extraurl : "/ws-football-full" ,  linktype : "local"},
            {icon: '/img/homeicon/pc_live.png',icon_off: '/img/homeicon/pc_live.png',text: 'Live Casino', check: 'NEWS', url : "/bg-live-casino", extraurl : "/bg-live-casino-full" ,  linktype : "local"},
            {icon: '/img/homeicon/pc_fish1.png',icon_off: '/img/homeicon/pc_fish1.png',text: 'Fishing Master', check: 'Profile', url : "/bg-fishing-master", extraurl : "/bg-fishing-master-full" ,  linktype : "local"},
            // {icon: '/img/homeicon/pc_fish2.png',icon_off: '/img/homeicon/pc_fish2.png',text: 'Xiyou Fishing', check: 'ABOUT', url : "/bg-xiyoufishing", linktype : "local" },
            // {icon: '/img/homeicon/pc_fish3.png',icon_off: '/img/homeicon/pc_fish3.png',text: 'Daisen', check: 'ABOUT', url : "/bg-daisen", linktype : "local" },
    ],
    messages : {

        title : "အောက်ပါ ဖုန်းနံပါတ်များသည်",
        title_one : "အဖွဲ့အစည်း ၏ ဖုန်းနံပါတ်များ ဖြစ်ပါသည်။",
        company: "AThaPyar",
        company_one:"AThaPyar",
        day : "နေ့စဉ်(၂၄)နာရီပိတ်ရက်မရှိ ဝန်ဆောင်မှုပေးနေပါသည်",
        time:"ငွေသွင်းငွေထုတ် ဝန်ဆောင်မှုအချိန်",
        connect_one : "ဝန်ဆောင်မှု ဖုန်းနံပါတ်",
        viber:"ဖြင့်ဆက်သွယ် ပေးပါ",
        connect : "Viber",
        add_one: "နဲ့ ပတ်သက်ပြီး သိရှိလိုသည်များကို",

        add_two : "ဤနေရာတွင် နှိပ်၍ ကြည့်ရှုနိုင်ပါသည်။",
        last_six_digit_transaction : "ကျေးဇူးပြု၍ ဘဏ်လုပ်ငန်းစဉ်နံပါတ်၏ နောက်ဆုံးဂဏန်း 6 လုံးကို ထည့်သွင်းပါ။",
        choose_bank     : "ဘဏ်ကိုရွေးချယ်ပါ။",
        choose_top_bank : "ငွေဖြည့်ရန် ဘဏ်တစ်မျိုးကို ရွေးချယ်ပါ။",
        choose_withdraw_bank : "ငွေထုတ်ရန် ဘဏ်တစ်မျိုးကို ရွေးချယ်ပါ။",
        cash_amount     : "ငွေသားပမာဏ",
        que_submit      : "ပေးပို့ချင်တာသေချာပါသလား",
        invalid_phone   : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။",
        welcome_message : "AThaPyar မှကြိုဆိုပါသည်",
        sending         : "လုပ်ဆောင်နေပါသည်",
        somethingwrong  : "အချက်အလက်များ မှားယွင်းနေပါသည်။",
        your_comment    : 'သင့်ရဲ့မှတ်ချက်',
        not_available_yet : "လုပ်ဆောင်နေပါသည်။",
        good            : "မင်္ဂလာ",
        good_morning    : "နံနက်ခင်းလေးပါ",
        good_afternoon  : "‌နေ့လည်ခင်းလေးပါ",
        good_evening    : "ည‌နေခင်းလေးပါ",
        good_night      : "ညချမ်းလေးပါ",
        lucky_number    : "ကံကောင်းစေသောနံပါတ်",
        any_trouble_viber : "အဆင်မပြေမှုရှိပါက viber သို့ ဆက်သွယ်ပေးပါ။",
        share_your_friend : "ဒါတွေကို အသုံးပြုပြီး သင့်သူငယ်ချင်းကို မျှဝေပါ။",
        feedback_title          :  "AThaPyar အက်ပ်ပလီကေးရှင်းအတွက်အကြံပြုစာ",
        feedback_second_title   :  "App သုံးရတာပိုမိုကောင်းမွန်ဖို့ ဒီနေရာလေးမှာအကြံပေးကြပါစို့",
        minamount         : "အနည်းဆုံးငွေ(၃၀၀၀)ကျပ်မှစ၍ ငွေထုတ်ရပါမည်",
        num_day : [
            { text: 'တနင်္ဂနွေနေ့'},
            { text: 'တနင်္လာနေ့'},
            { text: 'အင်္ဂါနေ့'},
            { text: 'ဗုဒ္ဓဟူးနေ့'},
            { text: 'ကြာသပတေး'},
            { text: 'သောကြာနေ့'},
            { text: 'စနေနေ့'},
        ],
    },
    errors : {
        contact_cs      : "Session Expired ဆက်ရှင် သက်တမ်းကုန်သွားပါပြီ။!!!",
        check_bank_acc  : "သင့်ဘဏ်အကောင့်ကို စစ်ဆေးပါ။",
        w_request_processing : "သင့်ရဲ့ ငွေထုတ်မှု့အားလုပ်ဆောင်နေပါသည် ခေတ္တ စောင့်ဆိုင်းပေးပါရှင့်",
        check_amount    : "သင့်ငွေပမာဏကို စစ်ဆေးပါ။",
    },
    moneyamountlist :  [
            { text: '1,000', num: '1000'},
            { text: '2,000',  num: '2000'},
            { text: '3,000',  num: '3000'},
            { text: '5,000', num: '5000'},
            { text: '10,000',  num: '10000'},
            { text: '50,000',  num: '50000'},
            { text: '100,000',  num: '100000'},
            { text: '200,000',  num: '200000'},
            { text: '500,000',  num: '500000'},
    ],
    betgroup : {
        select_number_group : "နံပါတ်အဖွဲ့ကို ရွေးပါ။",
        big     : "ကြီး",
        small   : "သေး",
        odd     : "မ",
        even    : "စုံ",
        same    : "အပူး",
        round   : "ပတ်လည်",
        head    : "ထိပ်",
        tail    : "နောက်ပိတ်",
        consetellation : "နက္ခတ်",
        power   : "ပါဝါ",
        select_number : "နံပါတ်ကို ရွေးပါ။",
        fast    : "အမြန်ရွှေး",
        clear   : "ပယ်ဖျက်ရန်",
        bet     : "ထိုးရန်",
        bet_amount : "ထိုးငွေ",
        commission : "ကော်မရှင်",
        action     : "လုပ်ဆောင်ချက်",
        edit       : "ပြင်ဆင်ရန်",
        delete     : "ဖျက်ရန်",
        commission_total : "ကော်မရှင်စုစုပေါင်း",
        total            : "စုစုပေါင်း",
        submit           : "လုပ်ဆောင်မည်",
        color_meaning    : "အရောင်အဓိပ္ပါယ်",
        color_box_one   : "၅၀ ရာခိုင်နူန်းအောက်",
        color_one : "အမဲရောင်",
        color_box_two   : " ၅၀ ရာခိုင်နူန်းမှ ၉၀ ရာခိုင်နူန်း အထိ ",
        color_two : "အစိမ်းရောင်",
        color_box_three : "၉၀ ရာခိုင်နူန်းအထက် ",
        color_three : "အဝါရောင်",
        color_four : "အနီရောင်",
        color_box_four : "ထိုး၍မရတော့ပါ",
        close_at         : "*ပိတ်ချိန် ",
        select_time      : "အချိန်ကိုရွေးချယ်ပါ။",
        same_number      : "ထွိုင်ဂဏန်း",
        same_numbers     : "ထွိုင်ဂဏန်းများ",
        hundred_number   : "ရာပြည့်",
        hundred_numbers   : "ရာပြည့်များ",
        lottery_holiday   : "ယနေ့ထီပိတ်ရက်ဖြစ်ပါသည်",
        tom_lottery_holiday : "နက်ဖြန်ထီပိတ်ရက်ဖြစ်ပါသည်",
    }
};


window.lang = window.mmlang;
