import React, { Component } from 'react';
import {
  Route,
  Switch,
  Redirect,
  Link
} from 'react-router-dom';
// import { FacebookProvider, Like } from 'react-facebook';

// import logo from './logo.svg';

import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min.js';
import 'react-day-picker/lib/style.css';

import './kitchen-sink.css';
import './App.css';
import './Reset.css';
import './Ant.css';
import './Color.css';
import './Component.css';
import './Gameui.css';

import WWBETLoginPage from './components/pages/WWBETLoginPage';

import Chat from './components/component/Chat/Chat';
import HomePage from './components/pages/news/HomePage';

import SubSuccessPage from './components/pages/news/SubSuccessPage';
import SubFailPage from './components/pages/news/SubFailPage';


import ListPage from './components/pages/ListPage';
import FeaturePage from './components/pages/FeaturePage';
import ListDetailPage from './components/pages/news/ListDetailPage';
import LeaguePage from './components/pages/news/LeaguePage';
import TopThreePage from './components/pages/league/TopThreePage';
import LeagueTablePage from './components/pages/league/LeagueTablePage';

import ContactUsPage from './components/pages/ContactUsPage';
import AboutPage from './components/pages/gaming/bg/AboutPage';
import LiveCasinoPage from './components/pages/gaming/bg/LiveCasinoPage';
import FishingPage from './components/pages/gaming/bg/FishingPage';

import LiveCasinoFullPage from './components/pages/gaming/bg/LiveCasinoFullPage';
import FishingFullPage from './components/pages/gaming/bg/FishingFullPage';

import WSFootballPage     from './components/pages/gaming/WSFootballPage';
import WSFootballFullPage from './components/pages/gaming/WSFootballFullPage';

import HelpCenterAppPage from './components/pages/live/HelpCenterAppPage';

import MorePage from './components/pages/MorePage';
import ContestPage from './components/pages/ContestPage';
import WalletTransfer from './components/pages/walletstore/WalletTransfer';
import WalletWithdrawalStore from './components/pages/walletstore/WalletWithdrawalStore';
import WalletTopUpStore from './components/pages/walletstore/WalletTopUpStore';
import TransactionStatus from './components/pages/walletstore/TransactionStatus';

import AddBank from './components/pages/walletstore/AddBank';
import BankList from './components/pages/walletstore/BankList';
import WalletStore from './components/pages/walletstore/WalletStore';

import MyAccPage from './components/pages/user/MyAccPage';
import MyEditAccPage from './components/pages/user/MyEditAccPage';

import ChangePasswordPage from './components/pages/user/ChangePasswordPage';
import BetList2DPage from './components/pages/user/BetList2DPage';
import BetList3DPage from './components/pages/user/BetList3DPage';
import StatementDetailPage from './components/pages/user/StatementDetailPage';
import StatementPage from './components/pages/user/StatementPage';

import ResultList2DPage from './components/pages/user/ResultList2DPage';
import ResultList3DPage from './components/pages/user/ResultList3DPage';
import BetList2DDetailPage from './components/pages/user/BetList2DDetailPage';
import BetList3DDetailPage from './components/pages/user/BetList3DDetailPage';

import ThaiOffDayPage from './components/pages/user/ThaiOffDayPage';
import RecordHistoryPage from './components/pages/user/RecordHistoryPage';
import DreamPhotoPage from './components/pages/user/DreamPhotoPage';
import WinnerTopTentwoDListPage from './components/pages/user/WinnerTopTentwoDListPage';
import WinnerTopTenthreeDListPage from './components/pages/user/WinnerTopTenthreeDListPage';

import AccPage from './components/pages/AccPage';

import FAQPage from './components/pages/user/FAQPage';
import TermandconditionsPage from './components/pages/user/TermandconditionsPage';
import SharePage from './components/pages/user/SharePage';

import InboxPage from './components/pages/user/InboxPage';
import TransactionPage from './components/pages/user/TransactionPage';
import OrderPage from './components/pages/user/OrderPage';
import LoginPage from './components/pages/LoginPage';
import SignUpPage from './components/pages/SignUpPage';
import FeedbackPage from './components/pages/FeedbackPage';

import SimLoginPage from './components/pages/SimLoginPage';
import AnnouncementsPage from './components/pages/user/AnnouncementsPage';
import AnnouncementsDetailPage from './components/pages/user/AnnouncementsDetailPage';

import TermAndCondition from './components/pages/TermAndConditionPage';
import AccBillHistoryPage from './components/pages/AccBillHistoryPage';
import AccLeagueHistoryPage from './components/pages/AccLeagueHistoryPage';

import PackageStorePage from './components/pages/PackageStorePage';
import PackagePage from './components/pages/PackagePage';

import UserPredictPackage from './components/pages/UserPredictPackage';


import SkillvideoPage from './components/pages/SkillvideoPage';
import SkillvideoDetailPage from './components/pages/SkillvideoDetailPage';

import ParticipantPage from './components/pages/ParticipantPage';

import ImagePreviewPage from './components/pages/ImagePreviewPage';

import IOSDownloadPage from './components/pages/IOSDownloadPage';
import AndroidDownloadPage from './components/pages/AndroidDownloadPage';
import DownloadPage from './components/pages/DownloadPage';

import HabaLoginHomePage from './components/pages/HabaLoginHomePage';
import HabaLoginPage from './components/pages/HabaLoginPage';

import FunkyLoginPage from './components/pages/FunkyLoginPage';


// import CommentPage from './components/pages/CommentPage';
import Toolbar from "./components/Toolbar";
import SideDrawer from "./components/sidemenu/SideDrawer";
import BackDrop from "./components/backdrop/BackDrop";


import NotiTestPage from './components/pages/NotiTestPage';

require('./BPdata.js')
// const data1 = Array.from(new Array(4)).map(() => ({
//   icon: 'https://gw.alipayobjects.com/zos/rmsportal/WXoqXTHrSnRcUwEaQgXJ.png',
// }));
//https://codepen.io/chriscoyier/pen/Wqyyqx



// data1 = data1.reverse();
let url = '/';



class App extends Component {

  constructor(props) {
    super(props)
    //console.log(props);
    this.state = {
      isApp: "false",
      url: '/',
      sideDrawerOpen: false,
      isLoading : true
    }
    this.langSwitch         = this.langSwitch.bind(this);


    //this.goBack = this.goBack.bind(this);
  }

  langSwitch() {

        if(localStorage.getItem('lang') === 'en') {
            localStorage.setItem('lang', 'mm');
            window.lang = window.mmlang;
        } else {
            localStorage.setItem('lang', 'en');
            window.lang = window.enlang;
        }

        window.location.reload();
        // console.log(window.lang)
        // this.props.history.push("/");
  }



  checklogin() {
    if(localStorage.getItem(window.BPdata.time)) {
        if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            return true;
        }
    }
    return false;
  }

  getCount() {
    const clicked = this.state.clicked
    if(clicked){
      this.setState({count: this.state.count - 1, clicked: false});
    } else {
      this.setState({count: this.state.count + 1, clicked: true})
    }

  }
  UNSAFE_componentWillMount() {
    if(!localStorage.getItem('lang')){
      localStorage.setItem('lang','mm');
    } else {
      if(localStorage.getItem('lang') === 'mm') {
        window.lang = window.mmlang;
      } else {
        window.lang = window.enlang;
      }
    }
  }
  componentDidMount() {

     window.BPdata.footerIs = "true";
    // this.window.BPdata.route =JSON.stringify( "/" );
      //console.log(localStorage.getItem('route'))

      var isApp = /AThaPyar/i.test(navigator.userAgent);

      if (isApp) {

          this.setState({
              isApp : "true"
          });

      } else {

        this.setState({
            isApp : "false"
        });

      }

      // this.setState({
      //     isApp : "true"
      // });

  }

  UNSAFE_componentWillUpdate() {
    //this.goBack();


    // } else {
    //   this.setState({ url: "/" });
    // }
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };


  render() {

    if (JSON.parse(localStorage.getItem("route")) !== null)  {
      let routes = JSON.parse(localStorage.getItem("route"));
      //console.log(routes.length)
        if(routes.length>1) {
          // if(window.BPdata.preurl ===  routes[routes.length-1]) {
          //   url = routes[routes.length-1] ;
          // } else {
            url = routes[routes.length-1] ;
          //}

        } else {
          url = "/";
        }

     }

    let backdrop;

    if (this.state.sideDrawerOpen) {
      // sideDrawer = <SideDrawer />;
      backdrop = <BackDrop click={this.backDropClickHandler} />;
    }

    return (
      <div className="App" >
        <div className="App-intro">
          <div id="fb-root"></div>
          <Switch>

            <Route path="/response/success" component={SubSuccessPage} />
            <Route path="/response/fail" component={SubFailPage} />

            <Route path="/faq" component={FAQPage} />
            <Route path="/help-center" component={HelpCenterAppPage} />
            <Route path="/share-app" component={SharePage} />
            <Route path="/termandconditions" component={TermandconditionsPage} />


            <Route path="/chat" component={Chat} />

            <Route path="/feature" component={FeaturePage} />
            <Route path="/latest" component={ListPage} />


            <Route path="/about" component={AboutPage} />


            <Route path="/ws-football" component={WSFootballPage} />
            <Route path="/wss-login" component={WWBETLoginPage} />

            <Route path="/bg-live-casino" component={LiveCasinoPage} />

            <Route path="/bg-fishing-master" component={FishingPage} />

            <Route path="/ws-football-full" component={WSFootballFullPage} />

            <Route path="/bg-live-casino-full" component={LiveCasinoFullPage} />

            <Route path="/bg-fishing-master-full" component={FishingFullPage} />
            {/*   <Route path="/bg-xiyoufishing" component={AboutPage} />
            <Route path="/bg-daisen" component={AboutPage} />
            <Route path="/bg-poker" component={AboutPage} /> */}


            <Route path="/contact-us" component={ContactUsPage} />


            <Route path="/news/league/:id/:name" component={LeaguePage} />
            <Route exact path="/news/:id" component={ListDetailPage} />


            <Route path="/image/:id" component={ImagePreviewPage} />

            <Route path="/skillvideo/:id/:name" component={SkillvideoDetailPage} />
            <Route path="/skillvideo" component={SkillvideoPage} />

            <Route path="/participant/predict/order/:id" component={UserPredictPackage} />


            <Route path="/more/wallet-store/transfer" component={WalletTransfer} />
            <Route path="/more/wallet-store/withdrawal" component={WalletWithdrawalStore} />
            <Route path="/more/wallet-store/top-up" component={WalletTopUpStore} />
            <Route path="/more/wallet-store/transaction-status" component={TransactionStatus} />

            <Route path="/more/wallet-store/add-bank" component={AddBank} />
            <Route path="/more/wallet-store/bank-list" component={BankList} />
            <Route path="/more/wallet-store" component={WalletStore} />

            <Route path="/more/announcements" component={AnnouncementsPage} />
            <Route path="/more/announcement/:id" component={AnnouncementsDetailPage} />

            {/* <Route path="/more/package" component={AnnouncementsPage} /> */}
            {/* <Route path="/more/announcement/:id" component={AnnouncementsDetailPage} /> */}


            <Route path="/more/noti-test" component={NotiTestPage} />

            <Route path="/more/myaccount/edit-user" component={MyEditAccPage} />
            <Route path="/more/myaccount" component={MyAccPage} />

            <Route path="/more/changepassword" component={ChangePasswordPage} />



            <Route path="/more/bet-list-2d/:id" component={BetList2DDetailPage} />
            <Route path="/more/bet-list-3d/:id" component={BetList3DDetailPage} />
            <Route path="/more/bet-list-2d" component={BetList2DPage} />
            <Route path="/more/bet-list-3d" component={BetList3DPage} />

            <Route path="/more/statement/:id" component={StatementDetailPage} />
            <Route path="/more/statement" component={StatementPage} />


            <Route path="/more/result-list-2d" component={ResultList2DPage} />
            <Route path="/more/result-list-3d" component={ResultList3DPage} />


            <Route path="/more/thai-off-day" component={ThaiOffDayPage} />
            <Route path="/more/record-history" component={RecordHistoryPage} />
            <Route path="/more/dream-photo" component={DreamPhotoPage} />
            <Route path="/more/2d-winner-top-ten-list" component={WinnerTopTentwoDListPage} />
            <Route path="/more/3d-winner-top-ten-list" component={WinnerTopTenthreeDListPage} />



            <Route path="/more/account/:id/bill-history" component={AccBillHistoryPage} />
            <Route path="/more/league" component={LeagueTablePage} />
            <Route path="/more/topthree" component={TopThreePage} />

            <Route path="/more/account/:id/league-history" component={AccLeagueHistoryPage} />
            <Route path="/more/account/:id" component={AccPage} />
            <Route path="/more/transaction" component={TransactionPage} />
            <Route path="/more/order" component={OrderPage} />
            <Route path="/more/inbox" component={InboxPage} />


            <Route path="/more/package" component={PackagePage} />

            <Route path="/more/login" component={SimLoginPage} />
            <Route path="/more/feedback" component={FeedbackPage} />
            <Route path="/more/sign-up" component={SignUpPage} />
            <Route path="/more/password-reset" component={LoginPage} />
            <Route path="/more/term-condition" component={TermAndCondition} />
            
            <Route path="/more" component={MorePage} />

            

            <Route path="/contest" component={ContestPage} />
            <Route path="/participant" component={ParticipantPage} />

            <Route path="/download/ios" component={IOSDownloadPage} />
            <Route path="/download/android" component={AndroidDownloadPage} />
            <Route path="/download" component={DownloadPage} />

            <Route path="/haba-login/:id" component={HabaLoginPage} />
            <Route path="/haba-login" component={HabaLoginHomePage} />

            <Route path="/funky-login/:id" component={FunkyLoginPage} />
            <Route path="/funky-login" component={HabaLoginHomePage} />

            <Route exact path="/" component={HomePage} />


            <Redirect to="/" />
          </Switch>

          {/* <div className="middle-ellipse"></div> */}
        </div>




      </div>
    );


  }

}

export default App;
